import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import SelectDialog from '../SelectDialog/SelectDialog';
import userStore from '../../stores/UserStore';
import webAppStore from '../../stores/WebAppStore';
import Footer from '../Footer/Footer';
import {ReactComponent as Dropdown} from '../../images/dropDown.svg';
import './UserProfile.scss';

const UserProfile = observer(() => {
  const navigate = useNavigate();
  const [phoneEmailError, setPhoneEmailError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
  }, []);
  const toggleEdit = () => {
    if (isEditing) {
      // Проверяем валидность данных перед сохранением
      if (!validateForm()) {
        return;
      }
      // TODO: Здесь можно добавить API-запрос для сохранения данных
      saveChanges();
    }
    setIsEditing(!isEditing);
  };
  const validateForm = () => {
    if (!userStore.isValidEmail(userStore.email)) {
      setPhoneEmailError('Неверный формат email');
      return false;
    }
    if (!userStore.isValidPhone(userStore.phone)) {
      setPhoneEmailError('Неверный формат номера телефона');
      return false;
    }
    if (!userStore.country) {
      setPhoneEmailError('Выберите страну');
      return false;
    }
    if (!userStore.city) {
      setPhoneEmailError('Выберите город');
      return false;
    }
    setPhoneEmailError('');
    return true;
  };
  const saveChanges = () => {
    // TODO: Здесь будет логика сохранения в API
    console.log('Saving changes:', {
      email: userStore.email,
      fio: userStore.firstName,
      phone: userStore.phone,
      country: userStore.country,
      city: userStore.city
    });
  };
  const showCountrySelector = () => {
    if (!isEditing) return;
    userStore.showCountryChangeBlock = !userStore.showCountryChangeBlock;
  };

  const showCitySelector = () => {
    if (!isEditing) return;
    if (userStore.country) {
      userStore.showCityChangeBlock = !userStore.showCityChangeBlock;
    }
  };

  const handleCountrySelect = (newCountry) => {
    userStore.setCountry(newCountry);
  };

  const handleCitySelect = (newCity) => {
    userStore.City = newCity;
  };

  const handleEmailChange = () => {
    if (userStore.isEditingEmail) {
      const newEmail = userStore.email;
      if (userStore.isValidEmail(newEmail)) {
        userStore.setEmail(newEmail);
        setPhoneEmailError('');
      } else {
        setPhoneEmailError('Неверный формат email');
        return;
      }
    }
    userStore.toggleEmailEdit();
  };
  const selectedCountry = userStore.countries.find(c => c.value === userStore.country);
  const selectedCity = userStore.cities.find(c => c.value === userStore.city);

  return (
    <div className="user-profile">
       <div className='layout'>
    
       </div>
       <div className="selector-block">
        <div className='change-button' onClick={toggleEdit}>
            {isEditing ? 'Сохранить' : 'Изменить'}
        </div>
        <input 
          type="text" 
          placeholder='Email' 
          className="profile-value" 
          value={userStore.Email} 
          onChange={(e) => isEditing  && userStore.setEmail(e.target.value)}
          autoComplete="off"
          readOnly={!isEditing}
        />
      </div>
      <input 
        type="text" 
        placeholder='ФИО'  
        className="profile-item" 
        value={userStore.Fio} 
        onChange={(e) => isEditing && userStore.setFio(e.target.value)}
        autoComplete="off"
        readOnly={!isEditing}
      />
      <div className={`selector-block ${!isEditing  ? 'disabled' : ''}`}  onClick={showCountrySelector}>
        <input 
          type="text" 
          placeholder='Страна'  
          className="profile-value" 
          value={selectedCountry?.label || ''} 
          autoComplete="off" 
          readOnly
        />
        { isEditing && <Dropdown style={{marginRight: '22px', alignSelf: 'center', cursor: 'pointer'}}/>}
      </div>
      <div 
        className={`selector-block ${!isEditing || !userStore.country ? 'disabled' : ''}`}
        onClick={showCitySelector}>
        <input 
          type="text" 
          placeholder='Город' 
          className="profile-value" 
          value={selectedCity?.label || ''} 
          autoComplete="off" 
          readOnly
        />
        { isEditing && <Dropdown style={{marginRight: '22px', alignSelf: 'center', cursor: 'pointer'}}/>}
      </div>
      <div className="selector-block">
        <input 
          type="text" 
          placeholder='Телефон'  
          className="profile-value" 
          value={userStore.Phone} 
          onChange={(e) => isEditing && userStore.setPhone(e.target.value)}
          autoComplete="off" 
          readOnly={!isEditing}
        />
      </div>
      {phoneEmailError && <div className="phone-error">{phoneEmailError}</div>}
      <div className="actions">
        <button className="action-btn" onClick={() => navigate('/request-history')}>
          История
        </button>
        <button className="action-btn" onClick={() => navigate('/active-requests')}>
         Заявки
        </button>
      </div>
      <Footer />
      {userStore.showCountryChangeBlock && (
        <SelectDialog
          isOpen={true}
          onSelect={handleCountrySelect}
          onClose={showCountrySelector}
          items={userStore.countries}
          title="Выберите страну"
        />
      )}

      {userStore.showCityChangeBlock && (
        <SelectDialog
          isOpen={true}
          onSelect={handleCitySelect}
          onClose={showCitySelector}
          items={userStore.availableCities}
          title="Выберите город"
        />
      )}
    </div>
  );
});
export default UserProfile;
