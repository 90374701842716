import React, { useState, useCallback } from 'react';
import { Paperclip,  XCircle, FileText, FileImage, ArchiveX } from 'lucide-react';
import './TextInput.scss';

const TextInput = ({ 
  value, 
  onChange, 
  placeholder = "", 
  label, 
  multiline = false, 
  rows = 3,
  onFileAttach
}) => {
  const [isShaking, setIsShaking] = useState(false);

  const [attachedFiles, setAttachedFiles] = useState([]);

  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value;
    onChange(newValue);
  }, [onChange]);
  
  const handleFileAttach = (e) => {
    const file = e.target.files[0];
    setAttachedFiles([...attachedFiles, file]);
    if (onFileAttach) {
      onFileAttach(file);
    }
  };

  const handleFileDelete = (index) => {
    const updatedFiles = [...attachedFiles];
    updatedFiles.splice(index, 1);
    setAttachedFiles(updatedFiles);
  };

  const getFileIcon = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <FileImage className="file-icon" />;
      case 'zip':
      case 'rar':
      case 'tar':
      case 'gz':
        return <ArchiveX className="file-icon" />;
      default:
        return <FileText className="file-icon" />;
    }
  };
 
  const InputComponent = multiline ? 'textarea' : 'input';
  
  return (
    <div 
      className={`text-input-container ${isShaking ? 'shake' : ''}`}
    >
      <div className="text-input-wrapper">
        <div className="text-input-field-wrapper">
          <InputComponent
            className={`text-input-field ${multiline ? 'multiline' : ''}`}
            style={{
              minHeight: multiline ? `${rows * 1.5}em` : 'auto'
            }}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            rows={multiline ? rows : undefined}
            autoComplete="off"
          />
          {!value && (
            <div 
              className={`text-input-placeholder ${multiline ? 'multiline' : ''}`}
            >
              {placeholder}
            </div>
          )}
        </div>
        
        {label && (
          <div className="text-input-label-wrapper">
            <div 
              className="text-input-label"
            >
              {label}
            </div>
            <label htmlFor={`file-input-${label}`} className="text-input-file-attach">
              <Paperclip size={18} />
              <input
                id={`file-input-${label}`}
                type="file"
                onChange={handleFileAttach}
                className="sr-only"
              />
            </label>
          </div>
        )}
      </div>
      {attachedFiles.length > 0 && (
        <div className="text-input-attached-files">
          {attachedFiles.map((file, index) => (
            <div key={index} className="text-input-attached-file">
              {getFileIcon(file)}
              <div className="file-name" >{file.name}</div>
              <div
                className="file-delete"
                onClick={() => handleFileDelete(index)}
              >
                <XCircle size={16} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TextInput;