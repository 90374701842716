import Button from '../Button/Button';

const AmlRisksModal = ({ risks, onClose }) => {
  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h2>Список рисков</h2>
        
        <div className="risksList">
          {risks.map((risk, index) => (
            <div key={index} className="riskItem">
              <span className={`riskPercentage ${risk.percentage > 50 ? 'high' : ''}`}>
                {risk.percentage}%
              </span>
              <span className="riskName">{risk.name}</span>
            </div>
          ))}
        </div>

        <Button type="btnTwo" onClick={onClose}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};
export default AmlRisksModal;