import './ErrorMessage.scss';
const ErrorMessage = () => {
    return (
      <div className="errorContainer">
        <div className="errorIcon">❌</div>
        <div className="errorText">
          Сервис временно недоступен
        </div>
        <div className="errorSubtext">
          Пожалуйста, попробуйте позже
        </div>
      </div>
    );
  };
  
  export default ErrorMessage;