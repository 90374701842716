import './Button.scss';

const Button = ({ type, onClick, children, disabled }) => {
  let buttonClassName = 'btn';
  if (type === 'btnOne') buttonClassName += ' btnOne';
  else if (type === 'btnTwo') buttonClassName += ' btnTwo';
  else if (type === 'btnThree') buttonClassName += ' btnThree';

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;