import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import WalletInput from '../WalletInput/WalletInput';
import Button from '../Button/Button';
import amlStore from '../../stores/AmlStore';
import webAppStore from '../../stores/WebAppStore';
import AmlRisksModal from './AmlRisksModal';
import AmlResult from './AmlResult';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import './AmlCheck.scss';

const AmlCheck = observer(() => {
  const [wallet, setWallet] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
    fetchQuotaWithError();
    
    return () => {
      amlStore.reset();
    };
  }, []);
  const fetchQuotaWithError = async () => {
    try {
      await amlStore.fetchQuota();
      setHasError(false);
    } catch (error) {
      setHasError(true);
    }
  };
  const handleCheck = async () => {
    try {
      await amlStore.checkWallet(wallet);
    } catch (error) {
      setHasError(true);
    }
  };

  if (amlStore.IsLoading) {
    return (
      <div className="loadingContainer">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="amlCheckPage">
      <div className="layout"></div>
      
      <div className="contentBlock">
        <h1 className="title">AML Проверка</h1>
        <p className="description">Введите адрес кошелька для проверки</p>
        {hasError && <ErrorMessage />}
        <div className="walletBlock">
          <WalletInput
            value={wallet}
            onChange={setWallet}
            onValidationChange={setIsValid}
            inputType="crypto"
            placeholder="Адрес кошелька TRC-20"
          />
          <div className="quotaInfo">
            Осталось проверок: {amlStore.Quota || 0}
          </div>
        </div>

        <div className="buttonsBlock">
          <Button
            type="btnTwo"
            onClick={handleCheck}
            disabled={!isValid || !amlStore.Quota}
          >
            Проверить кошелек
          </Button>
        </div>

        {amlStore.Result && (
          <AmlResult
            result={amlStore.Result}
            onShowRisks={() => amlStore.ShowRisksModal = true}
          />
        )}
      </div>

      {amlStore.ShowRisksModal && (
        <AmlRisksModal
          risks={amlStore.Result.risks_list}
          onClose={() => amlStore.ShowRisksModal = false}
        />
      )}
    </div>
  );
});

export default AmlCheck;
