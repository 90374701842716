import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import RequestItem from '../RequestItem/RequestItem';
import requestStore from '../../stores/RequestStore';
import webAppStore from '../../stores/WebAppStore';
import './ActiveRequests.scss';

const ActiveRequests = observer(() => {
  useEffect(() => {
    requestStore.fetchActiveRequests();
    webAppStore.updateBackButtonVisibility();
  }, []);

  return (
    <div className="activeRequest-screen">
      <div className="layout"></div>
      <div className="content">
        <h1>Активные заявки</h1>
        <div className="requests-list">
          {requestStore.ActiveRequests.map((request) => (
            <RequestItem
              key={request.number}
              number={request.number}
              type={request.type}
              amount={request.amount}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default ActiveRequests;