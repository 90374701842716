import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';
import {ReactComponent as RusFlag} from '../images/rusFlag.svg';
import {ReactComponent as AedFlag} from '../images/aedFlag.svg';
class UserStore {
  id = null;
  username = '';
  firstName = '';
  lastName = '';
  middleName = '';
  phone = '';
  email = '';
  city = null; 
  showCityChangeBlock = false;
  showCountryChangeBlock = false;
  isEditingPhone = false;
  isEditingEmail = false;
  countries = [
    { 
      value: 'RU', 
      label: 'Россия',
      icon: <RusFlag/>, 
    },
    { 
      value: 'AED', 
      label: 'ОАЭ',
      icon: <AedFlag/>, 
    },
  ];
  cities = [ 
    { value: 'Moscow', label: 'Москва', country: 'RU' },
    { value: 'Saint Petersburg', label: 'Санкт-Петербург', country: 'RU' },
    { value: 'Kazan', label: 'Казань', country: 'RU' },
    { value: 'Novosibirsk', label: 'Новосибирск', country: 'RU' },
    { value: 'Yekaterinburg', label: 'Екатеринбург', country: 'RU' },
    { value: 'Dubai', label: 'Дубай', country: 'AED' },
  ];
  constructor() {
    makeAutoObservable(this);
  }
  get availableCities() {
    return this.country ? this.cities.filter(city => city.country === this.country) : [];
  }
  setCountry(newCountry) {
    this.country = newCountry;
    this.city = null; 
  }
  setEmail(newEmail) {

    this.email = newEmail.trim();
  }

  setFio(value) {
    const words = value.toLowerCase().split(' ');
    const formattedFio = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
    this.firstName = formattedFio;
  }
  setPhone(newPhone) {
    let filteredPhone = newPhone.replace(/[^0-9+]/g, '');

    /*
    // Добавляем + в начало, если его нет
    if (filteredPhone.length > 0 && filteredPhone[0] !== '+') {
      filteredPhone = '+' + filteredPhone;
    }*/

    if (filteredPhone.startsWith('8')) {
      filteredPhone = '+7' + filteredPhone.slice(2);
    }

    this.phone = filteredPhone.slice(0, 15);
  }
  isValidPhone(phone) {
    const russianPhoneRegex = /^\+7\d{10}$/;
    const aedPhoneRegex = /^\+971\d{9}$/;
    return russianPhoneRegex.test(phone) || aedPhoneRegex.test(phone);
  }
  isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  togglePhoneEdit() {
    this.isEditingPhone = !this.isEditingPhone;
  }
  toggleEmailEdit() {
    this.isEditingEmail = !this.isEditingEmail;
  }
  async fetchUserData() {
    try {
      const response = await axios.get('/api/user');
      const { id, username, firstName, lastName, city, phone } = response.data;
      this.id = id;
      this.username = username;
      this.firstName = firstName;
      this.lastName = lastName;
      this.city = city;
      this.phone = phone;
    } catch (error) {
      console.error('Ошибка при загрузке данных пользователя:', error);
    }
  }
  set UserName(value){
    this.username = value;
  } 
  get UserName(){
    return toJS(this.username);
  }
  set Email(value){
    this.email = value;
  } 
  get Email(){
    return toJS(this.email);
  }
  set Phone(value){
    this.phone = value;
  }
  get Phone(){
    return toJS(this.phone);
  }
  set FirstName(value) {
    this.firstName = value;
  }
  get FirstName(){
    return toJS(this.firstName);
  }
  set LastName(value){
    this.lastName = value;
  }
  get LastName(){
    return toJS(this.lastName);
  }
  set MiddleName(value){
    this.middleName = value;
  }
  get MiddleName(){
    return toJS(this.middleName);
  }
  set City(newCity) {
    this.city = newCity;
  }
  get City(){
    return toJS(this.city);
  }
  set ShowCityChangeBlock(value){
    this.showCityChangeBlock = value;
  }
  get ShowCityChangeBlock(){
    return toJS(this.showCityChangeBlock);
  }
}
export default new UserStore();
