import React, { useState, useCallback } from 'react';
import './WalletInput.scss';

const WalletInput = ({ 
  value, 
  onChange,
  onValidationChange,
  inputType = 'crypto', 
}) => {
  const [isShaking, setIsShaking] = useState(false);
  const [error, setError] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  
  const validateWalletAddress = (address) => {
    if (!address) {
      return 'Поле обязательно для заполнения';
    }

    if (inputType === 'crypto') {
      if (address.length < 34 || address.length > 43) {
        return 'Неверная длина адреса кошелька';
      }
      if (!/^[a-zA-HJ-NP-Z0-9]+$/.test(address)) {
        return 'Адрес содержит недопустимые символы';
      }
    } else {
      const cleanNumber = address.replace(/\s/g, '');
      if (cleanNumber.length !== 16) {
        return 'Номер карты должен содержать 16 цифр';
      }
      if (!/^\d+$/.test(cleanNumber)) {
        return 'Номер карты может содержать только цифры';
      }
    }  
    return '';
  };
  const formatCardNumber = (value) => {
    const cleanNumber = value.replace(/\s/g, '');
    const groups = cleanNumber.match(/.{1,4}/g);
    return groups ? groups.join(' ') : cleanNumber;
  };
  const handleInputChange = useCallback((e) => {
    let newValue = e.target.value;
    if (inputType === 'card') {
      newValue = formatCardNumber(newValue);
    }

    setIsDirty(true);
    onChange(newValue);
    
    const validationError = validateWalletAddress(newValue);
    setError(validationError);
    onValidationChange?.(validationError === '');
    
    if (newValue && validationError && validationError !== 'Поле обязательно для заполнения') {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
    }
  }, [onChange, onValidationChange, inputType]);

  const handleBlur = useCallback(() => {
    if (isDirty) {
      const validationError = validateWalletAddress(value);
      setError(validationError);
      onValidationChange?.(validationError === '');
    }
  }, [value, isDirty, onValidationChange]);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text').trim();
    const normalizedText = pastedText.replace(/\s/g, '');
    
    if (/^[a-zA-Z0-9]*$/.test(normalizedText)) {
      onChange(normalizedText);
      
      const validationError = validateWalletAddress(normalizedText);
      setError(validationError);
      onValidationChange?.(validationError === '');
    } else {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
    }
  }, [onChange, onValidationChange]);

  return (
    <div className="wallet-input">
      <div 
        className={`custom-input-container ${isShaking ? 'shake' : ''} ${error && isDirty ? 'error' : ''}`}
      >
        <div className="custom-input-wrapper">
          <div className="custom-input-field-wrapper">
            <input
              className="custom-input-field"
              type="text"
              value={value}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onPaste={handlePaste}
              placeholder={inputType === 'crypto' ? 'Введите адрес кошелька' : 'Введите номер карты'}
              autoComplete="off"
              maxLength={inputType === 'card' ? 19 : undefined}
            />
          </div>
        </div>
      </div>
      {error && isDirty && (
        <div className="custom-input-error">
          {error}
        </div>
      )}
    </div>
  );
};

export default WalletInput;