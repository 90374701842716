import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TextInput from '../TextInput/TextInput';
import MoneyInput from '../MoneyInput/MoneyInput';
import Button from '../Button/Button';
import Footer from '../Footer/Footer';
import webAppStore from '../../stores/WebAppStore';
import requestStore from '../../stores/RequestStore';
import './VED.scss';

const VED = observer(() => {
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    requestStore.InvoicePayment = {amount: amount, comment: comment};
    requestStore.createVEDRequest()
  };

  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
  }, []);
  
  return (
    <div className="ved">
      <div className='layout'>
    
      </div>
      <div className="ved__header">
        <h1 className="ved__title">Создать заявку на ВЭД</h1>
      </div>
      <div className='ved__input-group'>   
          <div className='labelDescription'>Введите сумму</div>
          <MoneyInput
            value={amount}
            onChange={setAmount}
            placeholder="Сумма от 20 000$"
          />
      </div>
        <div className="ved__input-groupComment">
          <TextInput
            value={comment}
            onChange={setComment}
            placeholder="Комментарий"
            label="Комментарий"
            multiline={true}
            rows={4}
          />
        </div>
        <Button type="btnTwo" onClick={handleSubmit}  disabled={!amount || !comment}>
        Подтвердить заявку
        </Button> 
        <Footer/>
    </div>
);
});

export default VED;