import { makeAutoObservable } from 'mobx';

class ChatStore {
  messages = [];

  constructor() {
    makeAutoObservable(this);
  }

  addMessage(text, sender) {
    this.messages.push({ text, sender, timestamp: new Date() });
  }


  simulateSupportResponse() {
    setTimeout(() => {
      this.addMessage("Спасибо за ваш вопрос! Мы скоро с вами свяжемся.", "support");
    }, 1000);
  }

  sendMessage(text) {
    if (text.trim()) {
      this.addMessage(text, "client");
      this.simulateSupportResponse();
    }
  }
}

export default new ChatStore();