import React from 'react';
import './RequestItem.scss';

const RequestItem = ({ number, type, amount }) => {
  return (
    <div className="request-item">
      <div className="request-info">
        <div className="request-title">Заявка {number}</div>
        <div className="request-type">{type}</div>
      </div>
      <div className="request-amount">{amount}</div>
    </div>
  );
};

export default RequestItem;