import Button from '../Button/Button';

const AmlResult = ({ result, onShowRisks }) => {
    const formatDate = (dateStr) => {
      return new Date(dateStr).toLocaleString('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).replace(',', ' г. в');
    };
  
    const hasRisks = result.risks_list?.length > 0;
  
    return (
      <div className="resultBlock">
        <div className={`statusIcon ${hasRisks ? 'warning' : 'success'}`}>
          {hasRisks ? '⚠️' : '✓'}
        </div>
        
        <div className="statusText">
          {hasRisks 
            ? `Обнаружены риски (${result.risks_list.length})`
            : 'Рисков не обнаружено'
          }
        </div>
  
        <div className="infoGrid">
          <div className="infoItem">
            <span>Статус:</span>
            <span className={result.status === 'Active' ? 'active' : 'inactive'}>
              {result.status}
            </span>
          </div>
          
          <div className="infoItem">
            <span>Риск:</span>
            <span className={result.risk > 50 ? 'high-risk' : ''}>
              {result.risk}%
            </span>
          </div>
  
          <div className="infoItem">
            <span>Блокчейн:</span>
            <span>{result.blockchain}</span>
          </div>
  
          <div className="infoItem">
            <span>Кластер:</span>
            <span>{result.cluster}</span>
          </div>
        </div>
  
        <div className="addressBlock">
          <span>Адрес:</span>
          <span className="address">{result.address}</span>
        </div>
  
        {hasRisks && (
          <Button type="btnTwo" onClick={onShowRisks}>
            Открыть список рисков
          </Button>
        )}
  
        <div className="transactionsInfo">
          <div className="transactionItem">
            <span>Последняя транзакция:</span>
            <span>{formatDate(result.last_transaction)}</span>
          </div>
          
          <div className="transactionItem">
            <span>Первая транзакция:</span>
            <span>{formatDate(result.first_transaction)}</span>
          </div>
  
          <div className="transactionItem">
            <span>Количество транзакций:</span>
            <span>{result.transactions_count}</span>
          </div>
        </div>
      </div>
    );
  };
  export default AmlResult;