import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';
import {ReactComponent as RubleIcon} from '../images/ruble.svg';
import {ReactComponent as UsdtIcon} from '../images/usdt.svg';
import {ReactComponent as UsdIcon} from '../images/usd.svg';
import {ReactComponent as AedIcon} from '../images/aed.svg';
class RequestStore {
  activeRequests = [
    { number: 101, type: 'ВЭД', amount: '150,000 USD' },
    { number: 102, type: 'Инвойс', amount: '2,500 EUR' },
    { number: 103, type: 'Обмен', amount: '5 BTC' },
  ];
  requestHistory = [
    { number: 201, type: 'Инвойс', amount: '1,000 USD' },
    { number: 202, type: 'Обмен', amount: '3 ETH' },
    { number: 203, type: 'ВЭД', amount: '75,000 USD' },
  ];
  invoiceRequest = null;
  vedRequest = null;
  availableCurrencies = [
    { 
      type: "fiat",
      currencies: [
        { name: "usd", icon: <UsdIcon/> },
        { name: "rub", icon: <RubleIcon/> },
        { name: "aed", icon: <AedIcon/> },
      ]
    },
    {
      type: "crypto", 
      currencies: [
        { name: "usdt", icon: <UsdtIcon/> },
      ]
    }
  ];
  exchangeRates = {
    "usdt":{
      "rub" : 100,
      "usd" : 1,
      "aed" : 2,
    },
  };
  currencyTo = this.availableCurrencies[0].currencies[0];
  currencyFrom = this.availableCurrencies[1].currencies[0];
  amountTo = '';
  amountFrom = '';
  mode = 'toward';

  constructor() {
    this.currencyTo = this.availableCurrencies[0].currencies[0];
    this.currencyFrom = this.availableCurrencies[1].currencies[0];
    makeAutoObservable(this);
  }
  set CurrencyTo(currency) {
    this.currencyTo = currency;
  }
  get CurrencyTo(){
    return toJS(this.currencyTo);
  }

  set CurrencyFrom(currency) {
    this.currencyFrom = currency;
  }
  get CurrencyFrom(){
    return toJS(this.currencyFrom);
  }

  set AmountTo(amount) {
    this.amountTo = amount;
  }
  get AmountTo(){
    return toJS(this.amountTo);
  }

  set AmountFrom(amount) {
    this.amountFrom = amount;
  }
  get AmountFrom(){
    return toJS(this.amountFrom);
  }
  set Mode(mode) {
    this.mode = mode;
  }
  get Mode(){
    return toJS(this.mode);
  }
  set InvoiceRequest(value){
    this.invoiceRequest = value;
  }
  get InvoiceRequest(){
    return toJS(this.invoiceRequest)
  }
  set VedRequest(value){
    this.vedRequest = value;
  }
  get VedRequest(){
    return toJS(this.vedRequest)
  }
  set AvailableCurrencies(value){
    this.availableCurrencies = value;
  }
  get AvailableCurrencies(){
    return toJS(this.availableCurrencies);
  }
  get ActiveRequests(){
    return toJS(this.activeRequests);
  }
  set ActiveRequests(value){
    this.activeRequests = value;
  }
  get RequestHistory(){
    return toJS(this.requestHistory);
  }
  set RequestHistory(value){
    this.requestHistory = value;
  }
  switchCurrencies() {
    const tempCurrency = this.currencyTo;
    const tempAmount = this.amountTo;
    
    this.currencyTo = this.currencyFrom;
    this.currencyFrom = tempCurrency;
    this.amountTo = this.amountFrom;
    this.amountFrom = tempAmount;
  }
  setCurrencyTo(currency) {
    if (this.canSelectCurrency(currency, 'to')) {
      this.currencyTo = currency;
      if (this.amountFrom) {
        this.updateAmountTo(this.amountFrom);
      }
    }
  }
  setCurrencyFrom(currency) {
    if (this.canSelectCurrency(currency, 'from')) {
      this.currencyFrom = currency;
      if (this.amountTo) {
        this.updateAmountFrom(this.amountTo);
      }
    }
  }
  getExchangeRateBetweenCurrencies(fromCurrency, toCurrency) {
    if (!fromCurrency || !toCurrency) return 1;

    const fromType = this.getCurrencyType(fromCurrency);
    const toType = this.getCurrencyType(toCurrency);

    if (fromType === 'crypto' && toType === 'fiat') {
      return this.exchangeRates[fromCurrency.name][toCurrency.name] || 1;
    } else if (fromType === 'fiat' && toType === 'crypto') {
      return 1 / (this.exchangeRates[toCurrency.name][fromCurrency.name] || 1);
    }
    return 1;
  }
  updateAmountTo(value) {  
    if (value && value !== undefined) {
      this.amountTo = value;
      const rate = this.getExchangeRateBetweenCurrencies(this.currencyFrom, this.currencyTo);
      this.amountFrom = (parseFloat(value) * rate).toFixed(2);
    } else {
      this.amountFrom = '';
      this.amountTo = '';
    }
  }
  updateAmountFrom(value) {
    if (value) {
      this.amountFrom = value;
      const rate = this.getExchangeRateBetweenCurrencies(this.currencyFrom, this.currencyTo);
      this.amountTo = (parseFloat(value) / rate).toFixed(2);
    } else {
      this.amountFrom = '';
      this.amountTo = '';
    }
  }
  canSelectCurrency(currency, targetField) {
    // Получаем текущую валюту из противоположного поля
    const otherCurrency = targetField === 'to' ? this.currencyFrom : this.currencyTo;
    
    // Проверяем, не является ли выбираемая валюта той же самой
    if (currency.name === otherCurrency.name) {
      return false;
    }

    // Получаем типы валют
    const targetCurrencyType = this.getCurrencyType(currency);
    const otherCurrencyType = this.getCurrencyType(otherCurrency);

    // Проверяем, не являются ли типы валют одинаковыми
    return targetCurrencyType !== otherCurrencyType;
  }

  async fetchActiveRequests() {
    try {
      const response = await axios.get('/api/requests/active');
      //this.activeRequests = response.data;
    } catch (error) {
      console.error('Ошибка при загрузке активных заявок:', error);
    }
  }
  async fetchRequestHistory() {
    try {
      const response = await axios.get('/api/requests/history');
      //this.requestHistory = response.data;
    } catch (error) {
      console.error('Ошибка при загрузке истории заявок:', error);
    }
  }
  async createRequest(requestData) {
    try {
      const response = await axios.post('/api/requests', requestData);
      this.activeRequests.push(response.data);
    } catch (error) {
      console.error('Ошибка при создании заявки:', error);
    }
  }
  async createInvoceRequest(){
    try{
      this.InvoiceRequest = null;
    }catch(error){
      console.debug('Ошибка при создании заявки на оплату инвойса:',error)
    }
  }
  async createVEDRequest(){
    try{
      this.VedRequest = null;
    }catch(error){
      console.debug('Ошибка при создании заявки на ВЭД:',error)
    }
  }
  async fetchAvailableCurrencies() {
    try {
      const response = await axios.get('/api/currencies');
      //this.availableCurrencies = response.data;
    } catch (error) {
      console.error('Ошибка при загрузке доступных валют:', error);
    }
  }

  async fetchExchangeRates() {
    try {
      const response = await axios.get('/api/exchange-rates');
      //this.exchangeRates = response.data;
    } catch (error) {
      console.error('Ошибка при загрузке курсов обмена:', error);
    }
  }
  getCurrencyType(currency) {
    if (!currency) return null;
    
    for (const group of this.availableCurrencies) {
      if (group.currencies.some(c => c.name === currency.name)) {
        return group.type;
      }
    }
    return null;
  }
}

export default new RequestStore();