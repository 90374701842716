import React, { useEffect } from 'react';
import Button from '../Button/Button';
import Footer from '../Footer/Footer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import webAppStore from '../../stores/WebAppStore';
import './Menu.scss'

const Menu = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    searchParams.delete("error-background");
    webAppStore.updateBackButtonVisibility();
    setSearchParams(searchParams);
  }, []);

  return (
<div className='menuPage'>
  <div className='layout'>
    
  </div>
  <div className='headerBlock'>
    <h1 className='headerName'>Foex Pay</h1>
  </div>
  <div className='buttonsBlock'>   
    {[ "/create-request", "/invoice-payment", "/ved", "/aml-check", "/user-profile"].map((path, index) => (
      <Button key={index} type="btnOne" onClick={() => navigate(path)}>
      {index === 4 && "Личный кабинет"}
      {index === 0 && "Обмен USDT"}
      {index === 3 && "AML проверка"}
      {index === 1 && "Оплатить инвойс"}
      {index === 2 && "ВЭД импорт/экспорт"}
      </Button>     
    ))}
  </div>
  <Footer />
</div>
  );
};

export default Menu;
