import React, { useState, useCallback, useEffect } from 'react';
import './SelectDialog.scss';

const SelectDialog = ({
  isOpen,
  onClose,
  onSelect,
  title = 'Выберите значение',
  items = [],
  searchable = true,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
      setSelectedIndex(-1);
    }
  }, [isOpen]);


  useEffect(() => {
    if (searchable && searchQuery) {
      const filtered = items.filter(item =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems(items);
    }
  }, [searchQuery, items, searchable]);


  const handleKeyDown = useCallback((e) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < filteredItems.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0) {
          onSelect(filteredItems[selectedIndex].value);
          onClose();
        }
        break;
      case 'Escape':
        e.preventDefault();
        onClose();
        break;
      default:
        break;
    }
  }, [isOpen, filteredItems, selectedIndex, onSelect, onClose]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  if (!isOpen) return null;

  return (
    <div className="select-dialog-overlay" onClick={onClose}>
      <div 
        className="select-dialog"
        onClick={e => e.stopPropagation()}
      >
        <div className="close-button" onClick={() => onClose()}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L13 13M1 13L13 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </div>
        <div className="modal-title">{title}</div>

        {searchable && (
          <div className="select-dialog-search">
            <input
              type="text"
              placeholder="Поиск..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}

            />
          </div>
        )}

        <div className="select-dialog-content">
          {filteredItems.length > 0 ? (
            <div className="select-dialog-list">
              {filteredItems.map((item, index) => (
                <div
                key={item.name}
                className={`select-option ${selectedIndex === index ? 'selected' : ''}`}
                onClick={() =>{
                  onSelect(item.value);
                  onClose();
                }}
                onMouseEnter={() => setSelectedIndex(index)}
              >
                <div className="select-content">
                  {item?.icon && (
                    <span className="select-icon">{item.icon}</span>
                  )}
                  <span className="select-name">{item.label}</span>
                  <div className="radio-indicator" />
                </div> 
              </div>
              ))}
            </div>
          ) : (
            <div 
              className="select-dialog-empty"
            >
              Ничего не найдено
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectDialog;