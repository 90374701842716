import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import UserProfile from './components/UserProfile/UserProfile';
import ActiveRequests from './components/ActiveRequests/ActiveRequests';
import Exchange from './components/Exchange/Exchange';
import AmlCheck from './components/AMLCheck/AmlCheck';
import Chat from './components/Chat/Chat';
import Menu from './components/Menu/Menu';
import VED from './components/VED/VED';
import InvoicePayment from './components/InvoicePayment/InvoicePayment';
import RequestHistory from './components/RequestHistory/RequestHistory';
import webAppStore from './stores/WebAppStore';


const App = observer(() => {
  useEffect(() => {
    // Track initial visibility based on current path
    webAppStore.updateBackButtonVisibility();
    // Listen for history changes
    const handlePopState = () => {
      webAppStore.updateBackButtonVisibility();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/active-requests" element={<ActiveRequests />} />
            <Route path="/request-history" element={<RequestHistory />} />
            <Route path="/create-request" element={<Exchange />} />
            <Route path="/aml-check" element={<AmlCheck />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/ved" element={<VED />} />
            <Route path="/invoice-payment" element={<InvoicePayment />} />
          </Routes>
        </div>
      </Router>
  );
});

export default App;