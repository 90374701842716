import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import chatStore from '../../stores/ChatStore';

const Chat = observer(() => {
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatStore.messages]);

  const handleSendMessage = () => {
    if (message.trim()) {
      chatStore.sendMessage(message);
      setMessage('');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#f5f5f5' }}>
      {/* Список сообщений */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '1rem' }}>
        {chatStore.messages.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: msg.sender === 'client' ? 'flex-end' : 'flex-start',
              mb: 1,
            }}
          >
            <Paper
              sx={{
                padding: '0.75rem 1rem',
                maxWidth: '70%',
                bgcolor: msg.sender === 'client' ? '#e1f5fe' : '#c8e6c9',
              }}
            >
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {msg.text}
              </Typography>
              <Typography variant="caption" sx={{ textAlign: 'right', display: 'block', mt: 0.5 }}>
                {msg.timestamp.toLocaleTimeString()}
              </Typography>
            </Paper>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      {/* Поле ввода и кнопка отправки */}
      <Box sx={{ display: 'flex', padding: '1rem', backgroundColor: 'white', borderTop: '1px solid #ddd' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Введите сообщение..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          sx={{ marginRight: '0.5rem' }}
        />
        <Button variant="contained" color="primary" onClick={handleSendMessage}>
          Отправить
        </Button>
      </Box>
    </Box>
  );
});

export default Chat;